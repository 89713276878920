



























































































import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import { Global, Props, User } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import API from '@/modules/API';
import PropUtils from '@/modules/PropUtils';

@Component({
    components: {
    }
})
export default class AlertsReport extends Vue {

    @Global.State('timezone') projectTimezone;
    @Global.State('lang') lang;
    @Props.State('list') projectProperties;

    loading = true;
    isDatePickerOpen = false;
    date = null;
    tickets = [];
    group_id = null;
    noData = false;

    async mounted(){
        this.loading = true;
        this.noData = false;

        this.date = moment().tz(this.projectTimezone).format('YYYY-MM-DD');
        this.setGeneralData();
        if (!this.noData) await this.fetchTicketsByDate();
        this.loading = false;
    }

    setGeneralData(){
        const id_property = PropUtils.getProperty(this.projectProperties, 'project.group_id');
        this.group_id = id_property ? id_property.value.id : '';
    }
    
    async fetchTicketsByDate(){
        try {
            const url = `https://yoqci9gzri.execute-api.us-east-1.amazonaws.com/dashboard-api/tickets/search?query=state_id:(1 OR 2) tags:alert created_at:${this.date} group_id:${this.group_id}`;
            const response = await API.externalAPI(url, 'GET', {}, {}, true);
            if (!response.result.length || !response.assets.hasOwnProperty('Ticket')){
                this.noData = true;
            }else {
                this.noData = false;
                this.generateTicketsList(response);
            }
        } catch (e){
            this.noData = true;
        }
    }

    async updateTicketsList(){
        this.loading = true;
        await this.fetchTicketsByDate();
        this.loading = false;
    }

    getCabinetName(note){
        return note && note.split(',')[0];
    }

    getCabinetId(note){
        return note && note.split(',')[1];
    }

    generateTicketsList(response){
        this.tickets = [];
        this.tickets = Object.values(response.assets.Ticket).map((ticket) => ({
            id: ticket['id'], 
            cabinet_name: this.getCabinetName(ticket['note']),
            cabinet_id: this.getCabinetId(ticket['note']),
            title: ticket['title'],
            priority_id: ticket['priority_id']
        }));
    }

    @Watch('date')
    clearList(){
        this.tickets = [];
        this.noData = false;
    }
}
